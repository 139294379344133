import { useEffect, useState } from 'react';

import DaypartAdapter from '@tb-core/adapters/contentful/daypart/daypart-adapter';
import StandardHero from '@tb-core/components/styled/banners/standard-hero';
import { filterHeroModuleByUserStatus } from '@tb-core/components/styled/modules/helpers/show-module-by-user';
import { currentTimeIsBetween } from '@tb-core/helpers/browser/datetime';
import { getDaypartTimeRange } from '@tb-core/helpers/daypart';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';
import { GenericContentProps, GenericModuleProps } from '@tb-core/types';

interface DaypartStandardHeroProps {
    heros?: GenericContentProps[];
}

const DaypartStandardHero = ({ heros }: DaypartStandardHeroProps) => {
    const {
        contentGroupCollection: { items: contentModules },
        topicMeta: { dayparts: daypartsConfig }
    } = usePageContext().content.pageData;
    const { isLoggedIn } = useUserContext();

    // @todo use GenericContentType.Hero instead when we figure out the build problem
    const heroList =
        heros ||
        contentModules.filter(filterHeroModuleByUserStatus(isLoggedIn));

    const findDefaultHero = () =>
        heroList.find(
            hero => hero.daypart === null && hero.promoStartDateTime === null
        ) || ({} as GenericContentProps);

    const [activeHero, setActiveHero] = useState<GenericContentProps | null>(
        findDefaultHero()
    );

    const isDayparted = (dayparts: string | null) =>
        dayparts !== null && dayparts.length > 0;

    // Check heroList for a matching dayparted hero within browsers time range
    // else serach heroList for non-dayparted hero
    const updateActiveHero = (heroList: GenericContentProps[]) =>
        heroList.some(hero => {
            if (
                activeHero &&
                activeHero.daypart === null &&
                isDayparted(hero.daypart)
            ) {
                const { start_time, end_time } = getDaypartTimeRange(
                    DaypartAdapter(hero.daypart),
                    daypartsConfig
                );

                if (
                    start_time &&
                    end_time &&
                    currentTimeIsBetween(start_time, end_time)
                ) {
                    setActiveHero(hero);
                    return true;
                }
            }
            return false;
        }) || setActiveHero(findDefaultHero());

    useEffect(() => {
        if (heroList) {
            updateActiveHero(heroList);
        }
    }, [heroList]);

    return (
        <>
            {activeHero !== null && (
                <StandardHero {...(activeHero as GenericModuleProps)} />
            )}
        </>
    );
};

export default DaypartStandardHero;
